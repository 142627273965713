<script>
import ApiModeVuetable from '@src/components/ApiModeVuetable'
import QuestionnaireAnswersService from '@src/services/QuestionnaireAnswersService.js'
import Layout from '@layouts/main'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@components/PageHeader'
import pagination from '@src/mixins/pagination'
import swalFeedback from '@src/mixins/swalFeedback'
import filterVuetable from '@src/mixins/filterVuetable'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import ExportButton from '@/src/components/ExportButton.vue'
import questionnaireAnswersFields from '@src/fields/questionnaireAnswersFields'
import FilterLabelButton from '@/src/components/FilterLabelButton.vue'
import questionnaireAnswersModal from '@src/router/views/questionnaireAnswers/questionnaireAnswersModal'
import questionnaireAnswersFilterModal from '@src/router/views/questionnaireAnswers/questionnaireAnswersFilterModal'

const i18nCommon = 'COMMON'
const i18nKey = 'QUESTIONNAIRE_ANSWERS'

export default {
    components: {
        ApiModeVuetable,
        Layout,
        Breadcrumb,
        PageHeader,
        ExportButton,
        FilterLabelButton,
        questionnaireAnswersModal,
        questionnaireAnswersFilterModal
    },
    mixins: [
        swalFeedback,
        pagination,
        vuetableFormatters,
        filterVuetable,
        questionnaireAnswersFields
    ],
    data() {
        return {
            sortOrder: [
                {
                    field: 'updated_at',
                    direction: 'desc',
                },
            ],
            i18nCommon,
            i18nKey,
            data: [],
            perPage: 10,
            css: {},
            additionalParameters: {
                with: [
                    'businessUnit',
                    'pointOfSale',
                    'questionnaire',
                    'answers',
                    'user',
                ],
            },
            filters: {
                questionnaire: [],
                startedAtDateTime: {},
                endedAtDateTime: {},
                business_unit: [],
                point_of_sale: [],
                users: [],
                flags: [],
                regionals: [],
                chains: [],
                statesAndCities: [],
            },
            questionnaireAnswer: {
                id: 0,
                name: '',
            },
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nCommon,
                        suffix: 'report',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.questionnaire_answer',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nQuestionnaireAnswer() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.questionnaire_answer',
                modifier: 2,
            })
        },
    },
    methods: {
        fetch(url, params) {
            return QuestionnaireAnswersService.fetchVuetable(url, params)
        },

        createAdditionalParameters() {
            return this.formatParameters({
                with: [
                    'businessUnit',
                    'pointOfSale',
                    'questionnaire',
                    'answers',
                    'user',
                ],
                city_id: this.filters.statesAndCities.filter((id) => typeof id === 'number'),
                states: this.filters.statesAndCities.filter((id) => typeof id === 'string'),
                chain_id: this.filters.chains.map((chain) => chain.id),
                regional_id: this.filters.regionals.map((regional) => regional.id),
                flag_id: this.filters.flags.map((flags) => flags.id),
                user_id: this.filters.users.map((unit) => unit.id),
                business_unit_id: this.filters.business_unit.map((unit) => unit.id),
                questionnaire_id: this.filters.questionnaire.map((questionnaire) => questionnaire.id),
                point_of_sale_id: this.filters.point_of_sale.map((pos) => pos.id),
                started_at_date_time: this.formatFilterDate(this.filters.startedAtDateTime),
                ended_at_date_time: this.formatFilterDate(this.filters.endedAtDateTime),
            })
        },

        filter(appliedFilters) {
            this.filters = appliedFilters;
            this.additionalParameters = this.createAdditionalParameters()
            this.refreshTable()
        },

        showFilterModal() {
            this.$nextTick(() => this.$refs.questionnaireAnswersFilterModal.showModal(this.filters))
        },

        openAnswers(data) {
            this.questionnaireAnswer.id = data.id
            this.questionnaireAnswer.name = data.questionnaire.name
            this.$nextTick(() => this.$refs.questionnaireAnswersModal.showModal())
        },

        refreshTable() {
            this.$nextTick(() => {
                this.$refs.vuetable.refresh()
            })
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3">
                <div class="col-sm-6 mb-3 form-inline"></div>
                <div class="col-sm-6 mb-3">
                    <div class="text-sm-right">
                        <filter-label-button @onClick="showFilterModal" />
                        <export-button
                            v-if="$can('CREATE', 'EXPORTJOB')"
                            type="questionnaire_answer"
                            :create-parameters="createAdditionalParameters"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nQuestionnaireAnswer"/>
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="questionnaireAnswersFields"
                        :per-page="perPage"
                        :display-selection-records-per-page="true"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="answers" slot-scope="props">
                            <div v-if="$can('READ', 'ANSWER')" class="text-center">
                                <b-link
                                    id="answers-modal"
                                    href="#"
                                    class="action-icon"
                                    @click="openAnswers(props.rowData)"
                                >
                                    <i class="fe-file" />
                                </b-link>
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <questionnaire-answers-modal
            ref="questionnaireAnswersModal"
            :questionnaire-answer="questionnaireAnswer"
        />
        <questionnaire-answers-filter-modal
            ref="questionnaireAnswersFilterModal"
            :handle-submit="filter"
        />
    </Layout>
</template>
